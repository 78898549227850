import Layout from "@/views/menu/index";
export default [
  {
    path: "/",
    name: "Menu",
    component: Layout,
    meta: {
      title: "宜和救援"
    },
    children: [
      {
        path: "/product/order/list",
        name: "ProductOrderList",
        component: () => import("@/views/product/order/list.vue"),
        meta: {
          auth: true,
          title: "订单管理"
        }
      },
      {
        path: "/mine",
        name: "Mine",
        component: () => import("@/views/mine/index.vue"),
        meta: {
          auth: true,
          title: "我的"
        }
      }
    ]
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/index.vue"),
    meta: {
      title: "登录"
    }
  }
];
