import { request } from "@/api/service";
import store from "@/store";
import { mergeWith } from "lodash";
export default async function getUserInfo(cb) {
  // 获取菜单
  // request({
  //   url: "/afis-auth/auth/user/role/moduleByWX",
  //   method: "get",
  //   data: {},
  // }).then((res) => {
  //   let currentMenus = [];
  //   if (!isArray(res)) {
  //     router.go(-1);
  //     return false;
  //   }
  //   res &&
  //     res.map((item) => {
  //       currentMenus.push(item.path);
  //     });
  //   store.dispatch("user/setCurrentMenus", currentMenus);
  //   if (currentMenus.length > 0) {
  //     router.push({
  //       path: currentMenus[0],
  //     });
  //   } else {
  //     router.push({
  //       path: "/mine",
  //     });
  //   }
  // });
  // 获取头像 /auth/authAppUser/info
  const res =  await request({
    url: "/afis-auth/auth/authAppUser/info",
    method: "get",
    data: {}
  });
  let userInfo = store.getters.info;
  userInfo.headImgUrl = res && res.headImgUrl;
  store.dispatch("user/set", userInfo);
  // 获取用户名称
  const r =  await request({
    url: "/afis-auth/auth/user/current",
    method: "get",
    data: {}
  });
  mergeWith(userInfo, r);
  store.dispatch("user/set", userInfo);
  cb && cb();
}
