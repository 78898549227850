<template>
  <div>
    <router-view />
    <div class="tabs">
      <div class="tabs_fixed"><TabBar v-show="hideshow" /></div>
    </div>
  </div>
</template>
<script>
import TabBar from "./tabBar.vue";
export default {
  components: { TabBar },
  data() {
    return {
      docmHeight: window.innerHeight, // 默认屏幕高度
      showHeight: window.innerHeight, // 实时屏幕高度
      hideshow: true // 显示或者隐藏footer
    };
  },
  watch: {
    // showHeight: function(newValue) {
    //   if (this.docmHeight > newValue) {
    //     this.hideshow = false;
    //   } else {
    //     this.hideshow = true;
    //   }
    // }
  },
  created() {
    const routerPath = this.$route.path;
    if (routerPath === "/") {
      this.$router.replace({ path: "/mine" });
    }
  },
  mounted() {
    // window.onresize监听页面高度的变化
    window.onresize = () => {
      return (() => {
        this.showHeight = window.innerHeight;
      })();
    };
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
.tabs {
  height: 50px;
  &_fixed {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99999999999;
    background-color: $white;
    border-top: $background-color solid $border-radius-sm;
  }
}
</style>
