<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
if (process.env.NODE_ENV !== "production" && process.env.NODE_ENV !== "development") {
  // eslint-disable-next-line no-undef
  // let vConsole = new VConsole();
  // console.log(vConsole);
}
export default {};
</script>
<style lang="scss">
.van-button--round {
  border-radius: 10px !important;
  padding: 0px 15px !important;
}
.row-foot {
  padding: 6px 15px !important;
}
</style>
