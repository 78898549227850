<template>
  <div v-if="info.busiType === 'B'" class="tabBar">
    <div class="menu">
      <div>
        <router-link to="/product/order/list">
          <van-image width="25" height="25" :src="require('@/assets/images/menu/task_active.png')" v-if="currentUrl === '/product/order/list'" />
          <van-image width="25" height="25" :src="require('@/assets/images/menu/task.png')" v-else />
          <span>订单</span>
        </router-link>
      </div>
      <div>
        <router-link to="/mine">
          <van-image width="25" height="25" :src="require('@/assets/images/menu/mine_active.png')" v-if="currentUrl === '/mine'" />
          <van-image width="25" height="25" :src="require('@/assets/images/menu/mine.png')" v-else />
          <span>我的</span>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return { info: (localStorage.getItem("afis-wx-user_info") && JSON.parse(localStorage.getItem("afis-wx-user_info"))) || {} };
  },
  computed: {
    currentUrl() {
      return this.$route.path;
    }
  }
};
</script>
<style lang="scss" scoped>
.menu {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 5px 0;
  div {
    display: inline-block;
    font-size: 12px;
    color: #444444;
    width: 50px;
    text-align: center;
    a {
      color: #444444;
    }
    span {
      display: block;
    }
  }
}
</style>
